<script>
import Layout from "../../../../layouts/main";
import appConfig from "@/app.config";
import CurrencyInput from "@/components/CurrencyInput";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    CurrencyInput,
    Layout,
  },
  data() {
    return {
      accounts: [],
      responseAccounts: [],
      journal: {},
      journals: [
        {
          descr: "",
          descr_en: "",
          type: "",
          value: null,
        },
        {
          descr: "",
          descr_en: "",
          type: "",
          value: null,
        },
      ],
    };
  },
  methods: {
    getLevelThreeAccounts() {
      this.http
        .post("level-three-chart-of-accounts-copy-1/ledger-accounts", {})
        .then((res) => {
          if (res.status) {
            this.accounts = res.data;
          }
        });
    },
    deleteTransactionOption(index) {
      this.journals.splice(index, 1);
    },
    selectedAccount(account) {
      this.http
        .post("level-three-chart-of-accounts-copy-1/accounts-setup/search", {
          search: account.search,
        })
        .then((res) => {
          if (res.status) {
            this.accounts = res.data;
            this.responseAccounts = res.data;
            this.accounts = res.data.map((a) => {
              return this.$i18n.locale == "ar"
                ? a.code + "-" + a.name
                : a.code + "-" + a.name_en;
            });
          }
        });
    },
    searchAccount(account) {
      // let levelThreeAccountId = account?.search?.split("-")[0];
      // account.accountId = levelThreeAccountId;

      console.log(this.responseAccounts);
      this.responseAccounts.forEach((element) => {
        if (this.$i18n.locale == "ar") {
          if (account.search == element.name + "(" + element.code + ")") {
            account.accountId = element.id;
          }
        } else {
          if (account.search == element.name_en + "(" + element.code + ")") {
            account.accountId = element.id;
          }
        }
      });
      this.accounts = [];
      this.responseAccounts = [];
      this.http
        .get("level-three-chart-of-accounts-copy-1/" + account.accountId)
        .then((res) => {
          if (res.status) {
            account.code = res.data.code;
          }
        });
      console.log(account);
      // this.http
      //   .post("level-three-chart-of-accounts-copy-1/accounts-setup", {
      //     leveThreeId: levelThreeAccountId,
      //     id: account.id,
      //   })
      //   .then((res) => {
      //     if (res.status) {
      //       console.log("done");
      //       account.search = "";
      //     }
      //   });
    },
    createJournal() {
      console.log(this.journals);
      this.http
        .post("transactions/journal", {
          transactions: this.journals,
          journal: this.journal,
        })
        .then((res) => {
          if (res.status) {
            this.journals = [
              {
                descr: "",
                descr_en: "",
                type: "",
                value: null,
              },
              {
                descr: "",
                descr_en: "",
                type: "",
                value: null,
              },
            ];
            this.journal = {};
          }
        });
    },
  },
  computed: {
    filteredAccounts() {
      return this.accounts.filter((account) => {
        return account;
      });
    },
    totalTransaction() {
      return (
        this.journals
          .filter((tr) => tr.type == "debit")
          .reduce((total, tr) => total + tr.value, 0) ==
        this.journals
          .filter((tr) => tr.type == "credit")
          .reduce((total, tr) => total + tr.value, 0)
      );
    },
    totalDebit() {
      let total = 0;
      this.journals.forEach((tr) => {
        if (tr.type == "debit") {
          total += tr.value ? parseFloat(tr.value) : 0;
        }
      });
      return total;
    },
    totalCredit() {
      let total = 0;
      this.journals.forEach((tr) => {
        if (tr.type == "credit") {
          total += tr.value ? parseFloat(tr.value) : 0;
        }
      });
      return total;
    },
  },
  created() {
    // this.getLevelThreeAccounts(1);
  },
};
</script>

<template>
  <Layout>
    <div class="row" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
      <div class="row align-items-center">
        <div class="d-flex align-items-center mb-4">
          <router-link :to="`/journal_entries`">
            <h4 class="m-0">
              {{ $t("journal.title") }}
            </h4>
          </router-link>
          <div class="mx-2">\</div>
          <div>{{ $t("journal.new_journal") }}</div>
        </div>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-12">
        <div class="card card-body">
          <div class="row mb-2">
            <div></div>
            <div>
              <button
                @click="
                  journals.push({
                    accountId: null,
                    descr: '',
                    descr_en: '',
                    type: 0,
                    value: null,
                  })
                "
                class="btn text-light float-end"
                style="background: #3f4149 !important"
              >
                <i class="bx bx-plus"></i>
                {{ $t("journal.transaction") }}
              </button>
            </div>
          </div>
          <form>
            <div
              v-for="(journal, index) in journals"
              :key="journal"
              class="journal-container"
            >
              <div class="row">
                <div class="col">
                  <AutoComplete
                    required
                    @keyup="selectedAccount(journal)"
                    v-model="journal.search"
                    :suggestions="filteredAccounts"
                    @complete="journal.search = $event.query"
                    style="color: #333 !important"
                  />
                </div>
                <div class="col">
                  <!-- <label class="inline" for="">description</label> -->
                  <textarea
                    required
                    v-model="journal.descr"
                    :placeholder="`${$t('journal.descr')}`"
                    class="form-control mb-2"
                    rows="1"
                    cols="4"
                    name=""
                    id=""
                  ></textarea>
                  <textarea
                    required
                    v-model="journal.descr_en"
                    :placeholder="`${$t('journal.descr_en')}`"
                    class="form-control"
                    rows="1"
                    cols="4"
                    name=""
                    id=""
                  ></textarea>
                </div>
                <div class="col">
                  <div class="form-check form-check-inline">
                    <input
                      v-model="journal.type"
                      class="form-check-input"
                      type="radio"
                      value="credit"
                      :id="'creditVal'+index"
                    />
                    <label class="form-check-label" :for="'creditVal'+index"
                      >{{ $t("journal.credit") }}
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      v-model="journal.type"
                      class="form-check-input"
                      type="radio"
                      value="debit"
                      :id="'debitVal'+index"
                    />
                    <label class="form-check-label" :for="'debitVal'+index"
                      >{{ $t("journal.debit") }}
                    </label>
                  </div>
                </div>
                <div class="col">
                  <div class="d-flex">
                    <currency-input
                      required
                      :placeholder="`${$t('journal.value')}`"
                      class="form-control"
                      style="display: inline; margin-inline-end: 4px"
                      v-model="journal.value"
                    />
                    <button
                      :disabled="journals.length <= 2"
                      class="btn btn-danger"
                      @click="deleteTransactionOption(index)"
                    >
                      <i class="bx bx-trash"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col hidden">
                <AutoComplete
                  style="color: #333 !important; visibility: hidden"
                />
              </div>
              <div class="col hidden">
                <!-- <label class="inline" for="">description</label> -->
                <textarea
                  style="visibility: hidden"
                  class="form-control mb-2"
                  rows="1"
                  cols="4"
                  name=""
                  id=""
                ></textarea>
                <textarea
                  style="visibility: hidden"
                  class="form-control"
                  rows="1"
                  cols="4"
                  name=""
                  id=""
                ></textarea>
              </div>
              <div class="col">
                <div class="form-check form-check-inline">
                  <input
                    style="visibility: hidden"
                    class="form-check-input"
                    type="radio"
                    value="credit"
                  />
                  <label
                    class="form-check-label"
                    style="margin-inline-end: 2rem"
                    >{{
                      totalDebit > totalCredit ? totalDebit - totalCredit : 0
                    }}
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    style="visibility: hidden"
                    value="debit"
                  />
                  <label class="form-check-label"
                    >{{
                      totalCredit > totalDebit ? totalCredit - totalDebit : 0
                    }}
                  </label>
                </div>
              </div>
              <div class="col-2 hidden">
                <!-- <label class="inline" for="">Value</label> -->

                <div class="d-flex">
                  <input
                    type="number"
                    class="form-control"
                    style="
                      display: inline;
                      margin-inline-end: 4px;
                      visibility: hidden;
                    "
                  />
                  <button
                    style="visibility: hidden"
                    class="btn btn-danger hidden"
                  >
                    <i class="bx bx-trash"></i>
                  </button>
                </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div></div>
              <div>
                <button
                  type="button"
                  role="button"
                  :disabled="
                    !totalCredit || !totalDebit || totalCredit != totalDebit
                  "
                  data-bs-toggle="modal"
                  data-bs-target="#addModal"
                  class="btn btn-primary float-end"
                >
                  {{ $t("journal.add") }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!--Start Add Modal-->
    <div
      class="modal fade"
      id="addModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <form @submit.prevent="createJournal">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {{ $t("journal.complete") }}
              </h5>
              <button
                type="button"
                class="btn-close"
                style="margin: 0"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div class="modal-body">
              <div class="row d-flex align-items-center">
                <div class="mb-3">
                  <label for="" class="form-label">{{
                    $t("journal.entry_descr")
                  }}</label>
                </div>
              </div>
              <div class="row d-flex align-items-center">
                <div class="mb-3">
                  <textarea
                    :placeholder="`${$t('journal.descr')}`"
                    name=""
                    v-model="journal.descr"
                    cols="10"
                    rows="5"
                    class="form-control mb-3"
                  ></textarea>
                  <textarea
                    :placeholder="`${$t('journal.descr_en')}`"
                    name=""
                    v-model="journal.descr_en"
                    cols="10"
                    rows="5"
                    class="form-control"
                  ></textarea>
                </div>
              </div>
            </div>
            <div
              class="modal-footer"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            >
              <button type="submit" class="btn btn-primary">
                {{ $t("popups.add") }}
              </button>
              <button
                data-bs-dismiss="modal"
                type="button"
                class="btn btn-danger"
              >
                {{ $t("popups.cancel") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--End Add Modal-->
  </Layout>
</template>

<style soped>
.journal-container {
  background: #fafafa;
  /* margin: 1rem; */
  border-radius: 0.5rem;
  padding: 1rem;
}
</style>
